import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Flyim Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
    paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
    toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
     paddingRight: 24,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
    menuButton: {
    marginRight: 30,
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  /////////dialog
  const [nopen, setnOpen] = React.useState(false);

  const handleClickOpen = () => {
    setnOpen(true);
  };

  const handleClose = () => {
    setnOpen(false);
  };
  /////////dialog拒绝恶俗
  const [noesu, noesuopen] = React.useState(false);

  const hc2o = () => {
    noesuopen(true);
  };

  const hc2 = () => {
    noesuopen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
        
          <Typography variant="h6" noWrap>
            Flyim
          </Typography>
          <IconButton color="inherit" onClick={handleClickOpen}>
            <Badge badgeContent={1} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            {<i class="mdui-icon material-icons">keyboard_arrow_left</i>}
          </IconButton>
        </div>

<Link href="/">
    <ListItem button>
      <ListItemIcon>
        {<i class="mdui-icon material-icons">home</i>}
      </ListItemIcon>
      <ListItemText primary="首页" />
    </ListItem>
</Link>
<Link href="https://fly.ydust.in">
    <ListItem button>
      <ListItemIcon>
        {<i class="mdui-icon material-icons">account_circle</i>}
      </ListItemIcon>
      <ListItemText primary="客户端" />
    </ListItem>
</Link>

    <ListItem button onClick={hc2o}>
      <ListItemIcon>
        {<i class="mdui-icon material-icons">announcement</i>}
      </ListItemIcon>
      <ListItemText primary="如何合理上网" />
    </ListItem>

      </Drawer>
 <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
<Paper className={classes.paper}>
{
<center>
<h2 class="mdui-typo-display-2">Flyim</h2> 
<div class="mdui-typo-body-1-opacity">这是一个全新的聊天场所，在这里，您能邀好友一起消遣娱乐；在这里，您也可以讨论产品和项目开发</div>
</center>
}
<Link href="https://fly.ydust.in">
<Button variant="contained" color="primary">
打开网页端
</Button>
</Link><Divider /><Divider />
<Alert severity="info" variant="outlined">目前我们仍然处于测试阶段，不代表最终客户端品质</Alert>
</Paper>
            </Grid>

<Grid item xs={12}>
<Paper className={classes.paper}>
 <Divider /> <Divider />
{

<div class="mdui-typo-body-1-opacity">Flyim的CDN由Cloudflare提供支持，Cloudflare是世界上最大、最快的云网络平台之一</div>
}
</Paper></Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
<Dialog
        open={nopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Flyim内测公告"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
经过长达数个月的开发，Flyim终于上线啦！我们已开启内测群组申请，详情请看网页客户端喔
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            好耶！
          </Button>

        </DialogActions>
      </Dialog>
<Dialog
        open={noesu}
        onClose={hc2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"合理上网"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
在注册Flyim之前请确保您已满12周岁，或在监护人监护下使用Flyim Inc.的产品。另外Flyim的社区守则与腾讯QQ基本一致，也就是说QQ里面不能发的在Flyim也不能发，违者将封禁并追究法律责任</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hc2} color="primary">
            好耶！
          </Button>

        </DialogActions>
</Dialog>
    </div>

  );
}